import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MailSharp from '@material-ui/icons/MailSharp';
import PhoneSharp from '@material-ui/icons/PhoneSharp';
import CheckSharp from '@material-ui/icons/CheckSharp';
import useTranslate from 'src/hooks/useTranslate';
import { isSuccess, post } from 'src/ajax';
import { HabilitetStatus, OppgaveStatus } from 'src/domain/enums';
import { oppgaverActions } from 'src/store/oppgaverStore';
import { sakerActions } from 'src/store/sakerStore';
import { BodyType } from 'src/components/Typography';
import { SelectableButton } from 'src/components/Buttons';
import IconLink from '../IconLink';
import Oppgave from '../../Oppgave';
import { FlexRow, Hr, MarginContainer, Ul, Li } from '../Oppgaver.style';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import { lagOppgaveForVurderHabilitet } from 'src/domain/oppgaver';
import { AppDispatch, useAppDispatch } from 'src/store';
import { Sak } from 'src/domain/sak';
import { selectVurderHabilitet } from 'src/store/selectors/oppgaverSelectors';
import { ParterOgAktørerDto } from 'src/domain/aktor';
import { ParterOgAktører } from '../../../Aktorer/ParterOgAktører';

type VurderHabilitetProps = {
  sak: Sak;
  parterOgAktører: ParterOgAktørerDto;
};

export default function VurderHabilitet({ sak, parterOgAktører }: VurderHabilitetProps) {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const history = useHistory();
  const showErrorMessage = useShowErrorMessage();
  const viewModel = useSelector(selectVurderHabilitet);
  const componentRef = useRef<HTMLDivElement>();
  const scrollToElement = () =>
    componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const submit = (habilitetStatus: HabilitetStatus) => {
    if (
      habilitetStatus === HabilitetStatus.ValgtHabil &&
      sak.habilitet.status === HabilitetStatus.ValgtHabil
    ) {
      // Allerede bekreftet habilitet - åpne neste oppgave uten å gjøre noe mer
      scrollToElement();
      åpneNesteOppgave(dispatch);
      return Promise.resolve();
    }

    if (habilitetStatus === HabilitetStatus.ValgtInhabil) {
      history.push('inhabil');
      return Promise.resolve();
    }

    return post(`/saker/${sak.sakId}/habilitet`, {
      habilitetStatus,
      aktoererOgParter: parterOgAktører.partOgAktørIder,
    }).then(response => {
      if (isSuccess(response)) {
        dispatch(oppgaverActions.setValgtHabilitet(habilitetStatus));
        dispatch(
          sakerActions.setHabilitetStatusForSak({
            sakId: sak.sakId,
            habilitetStatus: habilitetStatus,
            partOgAktørIderVedHabilitetsvurdering: parterOgAktører.partOgAktørIder,
          })
        );
        åpneNesteOppgave(dispatch);
      } else {
        showErrorMessage('mine-oppgaver.vurder-habilitet.feil-beskrivelse');
      }
    });
  };

  const setValgtHabilitet = (status: HabilitetStatus) => {
    if (status === HabilitetStatus.ValgtHabil || HabilitetStatus.ValgtInhabil) {
      submit(status);
    }
  };

  const { oppgaveStatus, frist } = lagOppgaveForVurderHabilitet(sak);

  const advarsel =
    sak.habilitet.status === HabilitetStatus.ValgtHabilitetErUtdatert &&
    translate('mine-oppgaver.vurder-habilitet.aktører-endret');

  const beskrivelse = translate(
    {
      [OppgaveStatus.IkkeTilgjengelig]: 'mine-oppgaver.vurder-habilitet.beskrivelse-ikke-utført',
      [OppgaveStatus.Utført]: 'mine-oppgaver.vurder-habilitet.beskrivelse-utført',
      [OppgaveStatus.IkkeUtført]: 'mine-oppgaver.vurder-habilitet.beskrivelse-ikke-utført',
      [OppgaveStatus.UnderBehandling]:
        'mine-oppgaver.vurder-habilitet.beskrivelse-under-behandling',
    }[oppgaveStatus]
  );

  return (
    <Oppgave
      advarsel={advarsel}
      tittel={translate('mine-oppgaver.vurder-habilitet.tittel')}
      beskrivelse={beskrivelse}
      oppgaveStatus={oppgaveStatus}
      open={viewModel.open}
      frist={frist}
      onClick={() => dispatch(oppgaverActions.toggleOpenVurderHabilitet())}
    >
      <div ref={componentRef} className="vurderHabilitet" />
      <MarginContainer>
        <Hr />
        <BodyType>{translate('mine-oppgaver.vurder-habilitet.list-tittel')}</BodyType>
        <Ul>
          <Li>{translate('mine-oppgaver.vurder-habilitet.list-uttalt')}</Li>
          <Li>{translate('mine-oppgaver.vurder-habilitet.list-interesse')}</Li>
          <Li>{translate('mine-oppgaver.vurder-habilitet.list-tilknytning')}</Li>
        </Ul>
        <Hr />
        <BodyType>{translate('mine-oppgaver.vurder-habilitet.spm-tilknytning')}</BodyType>
        <ParterOgAktører parterOgAktører={parterOgAktører} sak={sak} />
        <Hr />
        <BodyType>{translate('mine-oppgaver.vurder-habilitet.spm-habilitet')}</BodyType>
        <FlexRow>
          <SelectableButton
            selected={viewModel.habilitetStatus === HabilitetStatus.ValgtHabil}
            onClick={() => setValgtHabilitet(HabilitetStatus.ValgtHabil)}
          >
            {translate('mine-oppgaver.vurder-habilitet.knapp-habil')}
            {viewModel.habilitetStatus === HabilitetStatus.ValgtHabil ? <CheckSharp /> : <></>}
          </SelectableButton>
          <SelectableButton
            selected={viewModel.habilitetStatus === HabilitetStatus.ValgtInhabil}
            onClick={() => setValgtHabilitet(HabilitetStatus.ValgtInhabil)}
          >
            {translate('mine-oppgaver.vurder-habilitet.knapp-inhabil')}
            {viewModel.habilitetStatus === HabilitetStatus.ValgtInhabil ? <CheckSharp /> : <></>}
          </SelectableButton>
        </FlexRow>
        <Hr />
        <FlexRow>
          <IconLink
            Icon={MailSharp}
            href={`mailto:${sak.domstol.epost}`}
            text={sak.domstol.epost}
          />
          <IconLink
            Icon={PhoneSharp}
            href={`tel:${sak.domstol.telefon}`}
            text={sak.domstol.telefon}
            style={{ marginRight: 'auto' }}
          />
        </FlexRow>
      </MarginContainer>
    </Oppgave>
  );
}

function åpneNesteOppgave(dispatch: AppDispatch) {
  dispatch(oppgaverActions.closeVurderHabilitet());
  dispatch(oppgaverActions.openBekreftOppmøte());
}
