import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sakerActions } from 'src/store/sakerStore';
import { isSuccess, multipartPost } from 'src/ajax';
import useTranslate from 'src/hooks/useTranslate';
import { withAkseptertBrukervilkårPage } from 'src/hoc/withPage';
import withRouteSakId from 'src/hoc/withRouteSakId';
import { withSak } from 'src/hoc/withSaker';
import withLoader from 'src/hoc/withLoader';
import { H1, BodyType, H3 } from 'src/components/Typography';
import { PrimaryButton, SecondaryButton } from 'src/components/Buttons';
import Textarea from 'src/components/InputFields/Textarea';
import { oppgaverActions } from 'src/store/oppgaverStore';

import { Form, Field } from 'react-final-form';
import { SøknadForm, Ul, Li } from './Søknad.style';
import useShowErrorMessage from 'src/hooks/useShowErrorMessage';
import Footer from './Footer';
import createBase64EncodedSha256Hash from 'src/utils/checksum';
import { HabilitetStatus } from 'src/domain/enums';
import { composeValidators, maxLength, required } from 'src/utils/validators';
import { Sak } from 'src/domain/sak';
import { SoeknadOmInhabilitet } from 'src/domain/soeknadOmInhabilitet';
import { withFetchParterOgAktører } from 'src/hoc/withFetch';
import { ParterOgAktørerDto } from 'src/domain/aktor';

type FormValues = {
  files?: File[];
  begrunnelse?: string;
};

type InhabilProps = {
  sak: Sak;
  parterOgAktører: ParterOgAktørerDto;
};

const Inhabil = ({ sak, parterOgAktører }: InhabilProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const showErrorMessage = useShowErrorMessage();
  const [isLoading, setIsLoading] = useState(false);

  const validateBegrunnelse = composeValidators(
    required(translate('søknad.textarea-feil')),
    maxLength(translate('validation.maxLength', 500), 500)
  );

  const gåTilSak = () => history.replace(`/saker/${sak.sakId}/mine-oppgaver`);

  const onSubmit = async ({ begrunnelse = '', files = [] }: FormValues) => {
    setIsLoading(true);

    const checksums = await Promise.all(files.map(createBase64EncodedSha256Hash));
    const data: SoeknadOmInhabilitet = {
      sakId: sak.sakId,
      begrunnelse,
      embete: sak.domstol.kode,
      aktørIder: parterOgAktører.partOgAktørIder,
      checksums,
    };

    const response = await multipartPost('/saker/inhabil', data, files);

    if (isSuccess(response)) {
      dispatch(oppgaverActions.setValgtHabilitet(HabilitetStatus.ValgtInhabil));
      dispatch(oppgaverActions.closeVurderHabilitet());
      dispatch(
        sakerActions.setHabilitetStatusForSak({
          sakId: sak.sakId,
          habilitetStatus: HabilitetStatus.ValgtInhabil,
          partOgAktørIderVedHabilitetsvurdering: parterOgAktører.partOgAktørIder,
        })
      );
      gåTilSak();
    } else {
      showErrorMessage('søknad.innsending-feil');
      setIsLoading(false);
    }
  };

  return (
    <div>
      <H1>{translate('søknad.inhabil.tittel')}</H1>
      <BodyType>{translate('søknad.inhabil.domstol-info-1')}</BodyType>
      <br />
      <br />
      <BodyType>{translate('søknad.inhabil.domstol-info-2')}</BodyType>
      <br />
      <br />
      <BodyType>{translate('søknad.inhabil.domstol-info-3')}</BodyType>
      <H3 style={{ marginBlockEnd: '0.5em', marginBlockStart: '1.5em' }}>
        {translate('søknad.inhabil.undertittel-1')}
      </H3>
      <BodyType>{translate('søknad.inhabil.listetittel')}</BodyType>
      <Ul>
        <Li>{translate('mine-oppgaver.vurder-habilitet.list-uttalt')}</Li>
        <Li>{translate('mine-oppgaver.vurder-habilitet.list-interesse')}</Li>
        <Li>{translate('mine-oppgaver.vurder-habilitet.list-tilknytning')}</Li>
      </Ul>
      <H3 style={{ marginBlockEnd: '0.5em', marginBlockStart: '1.5em' }}>
        {translate('søknad.inhabil.undertittel-2')}
      </H3>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <SøknadForm onSubmit={handleSubmit}>
            <BodyType style={{ marginTop: '0px' }}>{translate('søknad.inhabil.fordi')} *</BodyType>
            <Field
              name="begrunnelse"
              render={({ input, meta }) => (
                <Textarea
                  {...input}
                  errorMessage={meta.touched ? meta.error : undefined}
                  aria-required="true"
                  aria-label="begrunnelse"
                />
              )}
              validate={validateBegrunnelse}
            />

            <Footer epost={sak.domstol.epost} telefon={sak.domstol.telefon}>
              <SecondaryButton role="link" onClick={gåTilSak}>
                {translate('avbryt')}
              </SecondaryButton>
              <PrimaryButton type="submit" isLoading={isLoading}>
                {translate('søknad.domstol-send')}
              </PrimaryButton>
            </Footer>
          </SøknadForm>
        )}
      />
    </div>
  );
};

export default withAkseptertBrukervilkårPage(
  withRouteSakId(
    withSak(withFetchParterOgAktører(withLoader('søknad.henter', 'søknad.henter-feilet')(Inhabil)))
  )
);
